import React, { useState, useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { kebabCase, toNumber } from 'lodash'
import { useWallet } from 'use-wallet'
import { Toast, toastTypes } from '@pancakeswap-libs/uikit'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { QuoteToken, Team } from 'config/constants/types'
import { getBusdContract, getWbnbContract, getFesBNBContract } from 'utils/contractHelpers'
import { getWbnbAddress, getBusdAddress, getBNBBUSDLPAddress, getFesBNBLPAddress } from 'utils/addressHelpers'
import {
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
} from './actions'
import { State, Farm, Pool, Battlefield, ProfileState, TeamsState, AchievementState } from './types'
import { fetchProfile } from './profile'
import { fetchTeam, fetchTeams } from './teams'
import { fetchAchievements } from './achievements'


const ZERO = new BigNumber(0)

// Battlefield

export const useBattlefield = (): Battlefield[] => {
  const battlefield = useSelector((state: State) => state.battlefield.data)
  return battlefield
}

export const useBattlefieldFromPid = (pid): Battlefield => {
  const battlefield = useSelector((state: State) => state.battlefield.data.find((f) => f.pid === pid))
  return battlefield
}

export const useBattlefieldFromSymbol = (lpSymbol: string): Battlefield => {
  const battlefield = useSelector((state: State) => state.battlefield.data.find((f) => f.lpSymbol === lpSymbol))
  return battlefield
}

export const useBattlefieldUser = (pid) => {
  const battlefield = useBattlefieldFromPid(pid)

  return {
    allowance: battlefield.userData ? new BigNumber(battlefield.userData.allowance) : new BigNumber(0),
    tokenBalance: battlefield.userData ? new BigNumber(battlefield.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: battlefield.userData ? new BigNumber(battlefield.userData.stakedBalance) : new BigNumber(0),
    earnings: battlefield.userData ? new BigNumber(battlefield.userData.earnings) : new BigNumber(0),
    userArmyStrength: battlefield.userData ? new BigNumber(battlefield.userData.userArmyStrength) : new BigNumber(0),
    userArmyPercent: battlefield.userData ? new BigNumber(battlefield.userData.userArmyPercent) : new BigNumber(0),
  }
}


// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const [response, setResponse] = React.useState(0.0000)

  useEffect(() => {    
    const requestData = () => {
      fetch('https://api.coingecko.com/api/v3/coins/binancecoin?market_data=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(function(newResponse){
      return newResponse.json();
    })
    .then(function(myJson) {
      setResponse(myJson.market_data.current_price.usd)
    }).catch(err => setResponse(0));
  }
    requestData();
  }, []);
  return new BigNumber(response)
}

export const usePriceCakeBusd = (): BigNumber => {
  const bnbPriceUSD = usePriceEthBusd()
  const [price, setPrice] = React.useState(0.00000);
  
  // capture MOR per BNB
  useEffect(() => {
    const fetchPriceDetails = async () => {
      const fesBnbLPAddress = getFesBNBLPAddress();
      const fesContract = getFesBNBContract();
      const wbnbContract = getWbnbContract();
      let fesBalance = 0;
      let wbnbBalance = 0;
      const busd = getBusdAddress();
      const wbnb = getWbnbAddress();
      fesBalance = await fesContract.methods.balanceOf(fesBnbLPAddress).call()
      wbnbBalance = await wbnbContract.methods.balanceOf(fesBnbLPAddress).call()

      setPrice(wbnbBalance/fesBalance)
    }
  fetchPriceDetails()
  }, [])
  return new BigNumber(price).multipliedBy(bnbPriceUSD)
}

export const usePriceFesBusd = (): BigNumber => {
  const bnbPriceUSD = usePriceEthBusd()
  const [price, setPrice] = React.useState(0.00000);
  
  // capture MOR per BNB
  useEffect(() => {
    const fetchPriceDetails = async () => {
      const fesBnbLPAddress = getFesBNBLPAddress();
      const fesContract = getFesBNBContract();
      const wbnbContract = getWbnbContract();
      let fesBalance = 0;
      let wbnbBalance = 0;
      const busd = getBusdAddress();
      const wbnb = getWbnbAddress();
      fesBalance = await fesContract.methods.balanceOf(fesBnbLPAddress).call()
      wbnbBalance = await wbnbContract.methods.balanceOf(fesBnbLPAddress).call()
      setPrice(wbnbBalance/fesBalance)
    }
  fetchPriceDetails()
  }, [])
  return new BigNumber(price).multipliedBy(bnbPriceUSD)
}

export const usePriceEthBusd = (): BigNumber => {
  const [response, setResponse] = React.useState(0.0000)

  useEffect(() => {    
    const requestData = () => {
      fetch('https://api.coingecko.com/api/v3/coins/ethereum?market_data=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(function(newResponse){
      return newResponse.json();
    })
    .then(function(myJson) {
      setResponse(myJson.market_data.current_price.usd)
    }).catch(err => setResponse(0));
  }
    requestData();
  }, []);
  return new BigNumber(response)
}

export const usePriceBtcBusd = (): BigNumber => {
  const [response, setResponse] = React.useState(0.0000)

  useEffect(() => {    
    const requestData = () => {
      fetch('https://api.coingecko.com/api/v3/coins/bitcoin?market_data=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(function(newResponse){
      return newResponse.json();
    })
    .then(function(myJson) {
      setResponse(myJson.market_data.current_price.usd)
    }).catch(err => setResponse(0));
  }
    requestData();
  }, []);
  return new BigNumber(response)
}



export const usePriceDotBusd = (): BigNumber => {
  const [response, setResponse] = React.useState(0.0000)

  useEffect(() => {    
    const requestData = () => {
      fetch('https://api.coingecko.com/api/v3/coins/polkadot?market_data=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(function(newResponse){
      return newResponse.json();
    })
    .then(function(myJson) {
      setResponse(myJson.market_data.current_price.usd)
    }).catch(err => setResponse(0));
  }
    requestData();
  }, []);
  return new BigNumber(response)
}

export const usePricePancakeBusd = (): BigNumber => {
  const [response, setResponse] = React.useState(0.0000)

  useEffect(() => {    
    const requestData = () => {
      fetch('https://api.coingecko.com/api/v3/coins/pancakeswap-token?market_data=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(function(newResponse){
      return newResponse.json();
    })
    .then(function(myJson) {
      setResponse(myJson.market_data.current_price.usd)
    }).catch(err => setResponse(0));
  }
    requestData();
  }, []);
  return new BigNumber(response)
}

export const usePriceTableBusd = (): BigNumber => {
  const pid = 12 // TABLE-BNB LP
  const bnbPriceUSD = usePriceBnbBusd()
  return ZERO
}

export const usePriceLegendBusd = (): BigNumber => {
  const pid = 5 // LEGEND-BNB LP
  const bnbPriceUSD = usePriceBnbBusd()
  return ZERO
}

export const usePriceSquireBusd = (): BigNumber => {
  const pid = 6 // SQUIRE-BNB LP
  const bnbPriceUSD = usePriceBnbBusd()
  return ZERO
}

export const usePriceShillingBusd = (): BigNumber => {
  const pid = 9 // SHILLING-BNB LP
  const bnbPriceUSD = usePriceBnbBusd()
  return ZERO
}

// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// Profile

export const useFetchProfile = () => {
  const { account } = useWallet()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProfile(account))
  }, [account, dispatch])
}

export const useProfile = () => {
  const { isInitialized, isLoading, data, hasRegistered }: ProfileState = useSelector((state: State) => state.profile)
  return { profile: data, hasProfile: isInitialized && hasRegistered, isInitialized, isLoading }
}

// Teams

export const useTeam = (id: number) => {
  const team: Team = useSelector((state: State) => state.teams.data[id])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeam(id))
  }, [id, dispatch])

  return team
}

export const useTeams = () => {
  const { isInitialized, isLoading, data }: TeamsState = useSelector((state: State) => state.teams)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTeams())
  }, [dispatch])

  return { teams: data, isInitialized, isLoading }
}

// Achievements

export const useFetchAchievements = () => {
  const { account } = useWallet()
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchAchievements(account))
    }
  }, [account, dispatch])
}

export const useAchievements = () => {
  const achievements: AchievementState['data'] = useSelector((state: State) => state.achievements.data)
  return achievements
}

